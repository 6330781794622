.collaborations {
  background-color: #f2f3ef;
  hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    margin-top: 3rem;
  }
  hr:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: #f2f3ef;
  }
  ul li {
    list-style: none;
    position: relative;
    padding: 3px 0 2px 25px;
    line-height: 1.5em;
    margin: 5px 0 15px;
    font-size: 1.125em;
    padding: 0;
    position: relative;
    padding: 3px 0 2px 25px;
  }
  .collaborations-content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }
  .next-section {
    display: flex;
    float: right;
    font-style: italic;
  }
  @media screen and (min-width: 255px) {
    .collaborations-content {
      width: 300px;
    }
  }

  @media screen and (min-width: 510px) {
    .collaborations-content {
      width: 490px;
    }
  }
  @media screen and (min-width: 765px) {
    .collaborations-content {
      width: 745px;
    }
  }
  @media screen and (min-width: 1020px) {
    .collaborations-content {
      width: 1000px;
    }
  }
  ul li::before {
    content: "📰";
    position: absolute;
    top: 6px;
    left: 0;
  }
  ol {
    list-style: none;
    counter-reset: any-name;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  ol li {
    counter-increment: any-name;
    display: flex;
    width: 50%;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  ol li::before {
    content: "0" counter(any-name);
    font-weight: bold;
    font-size: 3rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    line-height: 0.7;
  }
  .responsive {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
