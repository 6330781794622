.calculator-container {
  color: #5d6063;
  background-color: #eaedf0;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #d6d9dc;
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 50px;
  margin: 0 0 40px 0;

  .form-row {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .form-row input {
    background-color: #ffffff;
    border: 1px solid #d6d9dc;
    border-radius: 3px;
    width: 100%;
    padding: 7px;
    font-size: 14px;
  }

  .form-row label {
    margin-bottom: 15px;
  }
  .input-submit {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #e50815;
    border: none;
    border-radius: 3px;
    padding: 10px 40px;
    cursor: pointer;
  }
  .input-submit:hover {
    background-color: #e50827;
  }
  .input-submit:active {
    background-color: #e5083a;
  }
}
