.homepage {
  display: flex;
  // background-color: #f2f3ef;
  // height: 100%;
  // width: 100vw;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 100%
    ),
    url(images/courtney-bear-dance-indigenous.jpg);
  // width: 100%;
  // height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100%;

  .main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 1000px;
  }
  img {
    border-radius: 50%;
  }
  .newspaper {
    margin-left: -1em;
    max-width: 80vw;
  }
  .next-section {
    display: flex;
    float: right;
    font-style: italic;
    position: relative;
    white-space: nowrap;
  }
  .next-section h4 {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 10px;
  }
  .next-section span {
    color: #f2f3ef;
  }
  @media screen and (min-width: 1020px) {
    .main-content {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .breakdown-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  // @media only screen and (min-width: 768px) {
  //   .main-content {
  //     justify-content: center;
  //   }
  // }
  @media screen and (min-width: 255px) {
    .main-content {
      width: 300px;
    }
  }

  @media screen and (min-width: 510px) {
    .main-content {
      width: 490px;
    }
  }
  @media screen and (min-width: 765px) {
    .main-content {
      width: 745px;
    }
  }
  @media screen and (min-width: 1020px) {
    .main-content {
      width: 1000px;
    }
  }
  .info-section {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    line-height: 2em;
    font-weight: 400;
    position: relative;
    padding: 3em;
    align-self: center;
    flex: 1;
    color: #f2f3ef;
  }

  p {
    letter-spacing: 0.5px;
  }

  .nav-left {
    display: flex;
    width: 6%;
    height: 100vh;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
  }

  .nav-line {
    width: 1px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 80px;
    background-color: #f2f3ef;
  }
  .social-link {
    color: #f2f3ef;
    // color: #000000;
  }

  .fa {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
  }

  .fa:hover {
    opacity: 0.7;
  }

  a {
    text-decoration: none;
  }
}
.breakdown-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
