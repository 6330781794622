.navbar {
  width: 100%;
  background: #fff;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  z-index: 500;
}

.nav-logo {
  height: 7rem;
  width: 7rem;
}
.nav-links {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}
.nav-links li {
  color: #fff;
  font-family: nyt-franklin, Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: 1.8rem;
  list-style: none;
}

.nav-link {
  text-decoration: none;
  color: #000000;
  transition: 0.3s all;
  margin-right: 1em;
}

.nav-link:hover {
  color: #676867;
}

.nav-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

@media only screen and (max-width: 1413px) {
  .navbar {
    position: relative;
  }

  .nav-link {
    color: #ffffff;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 110px;
    left: -100%;
    transition: 0.5s all;
  }

  .nav-links.active {
    background: #222222;
    left: 0;
  }

  .nav-item {
    padding: 10px 0;
    border-top: 1px solid #ffffff;
    /* border-bottom: 1px solid #ffffff; */
  }

  .nav-icon {
    display: flex;
    color: #000000;
  }
}
