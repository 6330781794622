* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: georgia, Times New Roman, serif;
}
p {
  font-size: 1.125em;
  line-height: 1.5em;
  padding-bottom: 24px;
}
h1 {
  font-size: 2rem;
  line-height: 1.5em;
  padding-bottom: 16px;
}
h2 {
  font-size: 1.5rem;
  line-height: 1.5em;
  padding-bottom: 16px;
}
h3 {
  font-size: 1.2rem;
  line-height: 1.5em;
  padding-bottom: 16px;
}
h4 {
  font-size: 1rem;
  line-height: 1.5em;
  padding-bottom: 16px;
}
hr {
  padding-top: 10px;
  padding-bottom: 10px;
}
a {
  text-decoration: underline;
  color: #e50815;
}
