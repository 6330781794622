.nonprofit-business {
  background-color: #f2f3ef;
  hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    margin-top: 3rem;
  }
  hr:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: #f2f3ef;
  }
  ul li {
    list-style: none;
    position: relative;
    padding: 3px 0 2px 25px;
    line-height: 1.5em;
    margin: 5px 0 15px;
    font-size: 1.125em;
    padding: 0;
    position: relative;
    padding: 3px 0 2px 25px;
  }
  .nonprofit-business-content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }
  .next-section {
    display: flex;
    float: right;
    font-style: italic;
  }
  @media screen and (min-width: 255px) {
    .nonprofit-business-content {
      width: 300px;
    }
  }

  @media screen and (min-width: 510px) {
    .nonprofit-business-content {
      width: 490px;
    }
  }
  @media screen and (min-width: 765px) {
    .nonprofit-business-content {
      width: 745px;
    }
  }
  @media screen and (min-width: 1020px) {
    .nonprofit-business-content {
      width: 1000px;
    }
  }
  ul li::before {
    content: "📰";
    position: absolute;
    top: 6px;
    left: 0;
  }
  .responsive {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .figure {
    display: flex;
  }
  // ol {
  //   list-style: none;
  //   counter-reset: any-name;
  //   display: flex;
  //   flex-wrap: wrap;
  //   margin: 0;
  //   padding: 0;
  // }
  // ol li {
  //   counter-increment: any-name;
  //   display: flex;
  //   width: 50%;
  //   font-size: 1rem;
  //   margin-bottom: 1rem;
  // }
  // ol li::before {
  //   content: "0" counter(any-name);
  //   font-weight: bold;
  //   font-size: 3rem;
  //   margin-right: 0.5rem;
  //   margin-left: 0.5rem;
  //   line-height: 0.7;
  // }
  /*** VARIABLES ***/
  /* Colors */
  $black: #1d1f20;
  $red: rgba(229, 8, 21, 0.5);
  $white: #fafafa;

  /*** EXTEND ***/
  /* box-shadow */
  %boxshadow {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
      0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  }

  /*** STYLE ***/
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: $white;
    color: $black;
    font-family: georgia, Times New Roman, serif;
  }

  main {
    display: block;
    margin: 0 auto;
    max-width: 40rem;
    padding: 1rem;
  }

  ol.numbered-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
    > li {
      background: white;
      border-radius: 0 0.5rem 0.5rem 0.5rem;
      @extend %boxshadow;
      counter-increment: gradient-counter;
      margin-top: 1rem;
      min-height: 3rem;
      padding: 1rem 1rem 1rem 3rem;
      position: relative;
      &::before,
      &::after {
        background: $red;
        border-radius: 1rem 1rem 0 1rem;
        content: "";
        height: 3rem;
        left: -1rem;
        overflow: hidden;
        position: absolute;
        top: -1rem;
        width: 3rem;
      }
      &::before {
        align-items: flex-end;
        @extend %boxshadow;
        content: counter(gradient-counter);
        color: $black;
        display: flex;
        font: 900 1.5em/1 georgia, Times New Roman, serif;
        justify-content: flex-end;
        padding: 0.125em 0.25em;
        z-index: 1;
      }
      @for $i from 1 through 5 {
        &:nth-child(10n + #{$i}):before {
          background: $red;
        }
      }
      @for $i from 6 through 10 {
        &:nth-child(10n + #{$i}):before {
          background: $red;
        }
      }
      + li {
        margin-top: 2rem;
      }
    }
  }
}
