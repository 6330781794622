.introduction {
  background-color: #f2f3ef;

  hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    margin-top: 3rem;
  }
  hr:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: #f2f3ef;
  }
  .introduction-content {
    //   width: 1000px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }
  .next-section {
    display: flex;
    float: right;
    font-style: italic;
  }
  @media screen and (min-width: 255px) {
    .introduction-content {
      width: 300px;
    }
  }

  @media screen and (min-width: 510px) {
    .introduction-content {
      width: 490px;
    }
  }
  @media screen and (min-width: 765px) {
    .introduction-content {
      width: 745px;
    }
  }
  @media screen and (min-width: 1020px) {
    .introduction-content {
      width: 1000px;
    }
  }
  :root {
    --primary-color: #212121;
    --background-color: #111;
    --font: sans-serif;
  }

  /* Timeline Container */
  .timeline {
    padding: 20px;
    margin: 0 auto;
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    .timeline {
      width: 100%;
    }
  }
  /* Card container */
  .card {
    position: relative;
    max-width: 400px;
  }
  ul li {
    list-style: none;
    position: relative;
    padding: 3px 0 2px 25px;
    line-height: 1.5em;
    margin: 5px 0 15px;
    font-size: 1.125em;
    padding: 0;
    position: relative;
    padding: 3px 0 2px 25px;
  }

  ul li::before {
    content: "📰";
    position: absolute;
    top: 6px;
    left: 0;
  }
  /* setting padding based on even or odd */
  .card:nth-child(odd) {
    padding: 30px 0 30px 30px;
  }
  .card:nth-child(even) {
    padding: 30px 30px 30px 0;
  }
  /* Global ::before */
  .card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid orangered;
  }

  /* Setting the border of top, bottom, left */
  .card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
  }

  /* Setting the border of top, bottom, right */
  .card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
  }

  /* Removing the border if it is the first card */
  .card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }

  /* Removing the border if it is the last card  and it's odd */
  .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }

  /* Removing the border if it is the last card  and it's even */
  .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }

  /* Information about the timeline */
  .info {
    display: flex;
    flex-direction: column;
    background: #333;
    color: gray;
    border-radius: 10px;
    padding: 10px;
  }

  /* Title of the card */
  .title {
    color: orangered;
    position: relative;
  }

  /* Timeline dot  */
  .title::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 999px;
    border: 3px solid orangered;
  }

  /* text right if the card is even  */
  .card:nth-child(even) > .info > .title {
    text-align: right;
  }

  /* setting dot to the left if the card is odd */
  .card:nth-child(odd) > .info > .title::before {
    left: -45px;
  }

  /* setting dot to the right if the card is odd */
  .card:nth-child(even) > .info > .title::before {
    right: -45px;
  }
  p {
    font-size: 1.125em;
    line-height: 1.5em;
  }
  .responsive {
    width: 100%;
    // max-width: 400px;
    height: auto;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
